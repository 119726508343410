export const environment = {
  env:"prod",
  production: true,
  homePageUrl:'/dashboard',
  notfoundPageUrl:'/error/error-404',
  loginPageUrl:'account/auth/login',
  invoicePageUrl: '/pricing/invoices',
  PageUrlMembers:'members/reviews-list',
  loginPageUrlMember:'members/auth/login',
  language:'fr',
  apiUrl: 'https://www.certishopping.com',
  apiUrlImage: 'https://www.certishopping.com/api',
  frontUrl: 'https://www.certishopping.com',
  fbAppId: 1099103878381074,
  fbConfigId:973170317989681,
  fbScope: "public_profile,email,pages_show_list,pages_read_engagement,pages_read_user_content",
  googleAppId: '935086043577-bt495s2pnl75u2a0vg9d86jdjeqb896o.apps.googleusercontent.com',
  fbVersion:"v15.0",
  WsUrl:'wss://www.certishopping.com/ws/',
  AppkeyShopify:'ae0978ebd1541b2c950a75619c94cd63',
  ScopeShopify: "write_products,read_orders,read_products,read_script_tags,write_script_tags,read_themes,read_content,write_content, read_customers, read_inventory",
  maxvalueplan:1000,
  fakeorderid:'fakeorderid',
  PayPalClientId: 'AVjSY2LxoDfLYaZorvSJ2Iy8uDRWhpJ5nyD0LCfsO31piu56cv_5im8GCcDQuVvp9l6lmCQNhV6zEr51',
  StripePublicKey:'pk_live_gbH4wQTvOTVZx99HrAywqstX',
  WixAppId :'d31521fe-3c23-4b63-8097-ba9ba3b06c1d',
  WixRedirectUrl:'https://www.certishopping.com/api/wix/authorize/',
  facebookTabnextUrl:'https://www.certishopping.com/api/facebook-tab/',
  fakeToken:"fakeToken.b3BlbnNzaC1rZXktdjEAAAAACmFlczI1Ni1jdHIAAAAGYmNyeXB0AAAAGAAAABA3XGRTvb",
  supportedLocales: ['fr', 'en'],
  recaptcha: {
    siteKey: '6LeoxV0kAAAAAPS74OONQb3l2BeGaz3T41CrR3Tg',
  },
  tiktok_key:'awd0cmh3yy52pyvm',
  dsn : "https://cd2a92c713734416bbf172f606f00742@123456asdsfdqsfdemo.certishopping.com/3",
  facebookRedirectUri: 'https://www.certishopping.com/settings/socialaccounts'
};



