
import { Injectable, PLATFORM_ID, Inject, OnDestroy } from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Account,IAccount } from 'src/app/core/models/account';
import { UserPlan, Plan } from "src/app/core/models/plan.models";
import { CookieService } from 'src/app/core/services/cookie.service';
import { User,Profile,LoginData } from 'src/app/core/models/auth.models';
import { SocialUser } from "src/app/core/models/social.user";
import { error } from 'protractor';
@Injectable({ providedIn: 'root' })
export class AuthenticationService implements  OnDestroy{
  apiUrl      = environment.apiUrl;
  frontUrl    = environment.frontUrl;
  user = new User();
  
    profile = new Profile();
    private currentAccountSubject: BehaviorSubject<any>;
    constructor(private http: HttpClient, private cookieService: CookieService, 
      @Inject(PLATFORM_ID) private platformId: any,
      private router: Router) {
        this.startStorageEventListener()
        this.apiUrl      = environment.apiUrl +`/${this.cookieService.getCurentLocale()}/api`;
    
    }
    ngOnDestroy() {
      this.stopStorageEventListener()
    }
 

    public  currentUser(): User {
        this.user =  this.cookieService.parseUser()
        if(this.user == null){
          this.logout()
          window.location.href = environment.loginPageUrl
        }
       
         
      return this.user;
    }

    public  CheckCurrentUserLogin(): User {
      this.user =  this.cookieService.parseUser();
      if(this.user){
        if(this.user.profile.is_client){
          return this.user;
        }
      }
      return null
      
    }

    public  currentMember(): User {
      this.user =  this.cookieService.parseUser()    
      return this.user;
    }

    public JWTToken(){
      const currentUser = this.cookieService.parseUser()
      if (currentUser && currentUser.token) {
        return currentUser.token
      }
      return null
    }

    public RefrechUser(){
      this.syc_account().subscribe(
        data=>{
              this.CacheUserData(data)
        }
      )
    }

    CacheUserData(data){
      this.user = new User(data.user);
      this.user.token = data.key;
      let profile = new Profile(data.profile)
      this.user.profile = profile
      this.user.first_name = profile.first_name
      this.user.last_name = profile.last_name
     
      this.user.setCompany(data.company)
      let company_id = null
      try {
        let str = JSON.parse(this.cookieService.getCookie('company_id'))
        company_id = Number(str)
      } catch (error) {
        company_id = null
      }
      if (company_id){
        this.user.selectCompany(company_id)
      }else{
        this.user.selectfirstCompany()
      }
        
      

     
      let site_id = null
      try {
        let str = JSON.parse(this.cookieService.getCookie('site_id'))
        site_id = Number(str)
      } catch (error) {
        site_id = null
      }
    
      if (site_id){
        this.user.selectSite(site_id)
      }else{
        this.user.selectfirstSite()
      }
      this.cookieService.setCookie('Confirm', this.user.is_verify.toString(), 30)
      this.cookieService.setCookie('user', JSON.stringify(this.user), 30)
    }

    public  CurrentUserPlan(): UserPlan{
      try {
        let User = this.currentUser()  
        let Company = User.getselectCompany()
        let plan:UserPlan = Company.plan
        return plan
      } catch (error){
        return null
      }
    }



    public get currentAccountValue(): Account {
        return this.currentAccountSubject.value;
    }

    login_Momeber(data){
      let user = new User();
      let profile = new Profile()
      profile.first_name = data.profile.first_name;
      profile.last_name = data.profile.first_name;
      profile.id = data.profile.id
      profile.is_client = data.profile.is_client
      profile.is_member = data.profile.is_member
      user.first_name = data.profile.first_name;
      user.last_name = data.profile.last_name;
      user.token = data.key;
      user.email = data.profile.email;
      user.id = data.user
      user.profile = profile

      this.cookieService.setCookie('user', JSON.stringify(this.user), 30)
      return this.user;

    }


    login(email: string, password: string) {
        return this.http.post<any>(`${this.apiUrl}/rest-auth/login/`, { email, password }).pipe(map((data:LoginData) => {
            this.user = new User(data.user);
            this.user.token = data.key;
            let profile = new Profile(data.profile)
            this.user.profile = profile
            this.user.first_name = profile.first_name
            this.user.last_name = profile.last_name
            this.user.setCompany(data.company)
            this.user.selectfirstCompany()
            this.user.selectfirstSite()
            this.cookieService.setCookie('Confirm', this.user.is_verify.toString(), 30)
            this.cookieService.setCookie('user', JSON.stringify(this.user), 30)

            return this.user;
          },error=>{
          }));
    }



    Faceboooklogin(data) {
      return this.http.post<any>(`${this.apiUrl}/facebook-rest-auth/login/`, data)
        .pipe(map((data:LoginData) => {
          
          this.user = new User(data.user);
          this.user.token = data.key;
          let profile = new Profile(data.profile)
          this.user.profile = profile
          this.user.first_name = profile.first_name
          this.user.last_name = profile.last_name
          this.user.setCompany(data.company)
          this.user.selectfirstCompany()
          this.user.selectfirstSite()

          this.cookieService.setCookie('Confirm', this.user.is_verify.toString(), 30)
          this.cookieService.setCookie('user', JSON.stringify(this.user), 30)
          return this.user;
        }));
  }

    logout() {
      if(this.user){
        try {
          if(this.user.profile.is_client){
            this.cookieService.deleteCookie('user');
            this.cookieService.deleteCookie('business');
            this.cookieService.deleteCookie('fbtoken');
            this.cookieService.deleteCookie('token_time');
            this.cookieService.deleteCookie('adaccount');
            this.cookieService.deleteCookie('Confirm');
            this.cookieService.deleteCookie('quick_start');
            this.cookieService.deleteCookie('shopifycode');
            this.cookieService.deleteCookie('facebookpage');
            this.cookieService.deleteCookie('site_id');
            this.cookieService.deleteCookie('Language');
            this.cookieService.deleteCookie('queryParams'); 
            this.cookieService.deleteCookie('company_id');
            this.user = null;
          }
        } catch (error) {}
      }
       
    }



    public register( account: any ): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/rest-auth/registration/`, account)
        .pipe(map((data:LoginData) => {
          
          let user = new User(data.user);
            user.token = data.key;
            let profile = new Profile(data.profile)
            user.profile = profile
            user.first_name = profile.first_name
            user.last_name = profile.last_name
            user.companies = data.company;
            // user.selectfirstCompany()
            // user.selectfirstSite()
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          return this.user;
        }));
    }

    public  syc_account( ): Observable <any> {
      return this.http.get<any>(`${this.apiUrl}/profiles/syc_account/`)
      .pipe(map(data => {
        return data
      }));
    }

    public  checkemail( data: any ): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/profiles/check_email/`, data)
      .pipe(map(data => {
        
        return data
      }));
    }

    public  checkphone( data: any ): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/companies/check_phone/`, data)
      .pipe(map(data => {
        
        return data
      }));
    }

    public  verifyemail( data: any, key:any ): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/rest-auth/registration/account-confirm-email/${key}/`, data)
      .pipe(map(data => {
        return data
      }));
    }

    public  passwordrest( data: any ): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/rest-auth/password/reset/`, data)
      .pipe(map(data => {
        return data
      }));
    }

    public  verifypasswordrest( data: any): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/rest-auth/password/reset/check/`, data)
      .pipe(map(data => {
        return data
      }));
    }

    public  confirmepasswordrest( data: any ,uidb64:string, token:string): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/password_reset_confirm/${uidb64}/${token}/`, data)
      .pipe(map(data => {
        return data
      }));
    }


    public  checksession( params ): Observable <any> {
      return this.http.get<any>(`${this.apiUrl}/shopify/check_session/`, {params})
      .pipe(map(data => {
        return data
      }));
    }

    public  checkEpagessession( session: any, shop:any ): Observable <any> {
      return this.http.get<any>(`${this.apiUrl}/epages/check_session/?session=${session}&shop=${shop}`)
      .pipe(map(data => {
        return data
      }));
    }

    public  checkWixsession(session): Observable<any> {
      return this.http.post(`${this.apiUrl}/wix/check_session/`,session)
      .pipe(map(data => {
        return data
      }));
    }
    public  checkActivateSession( token: any, site:any ): Observable <any> {
      return this.http.get<any>(`${this.apiUrl}/access/check-session/?token=${token}&site=${site}`)
      .pipe(map(data => {
        return data
      }));
    }
    public  activate(data): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/access/activate/`, data)
      .pipe(map(result => {
        return result
      }));
    }

    private startStorageEventListener(): void {
      window.addEventListener("storage", this.storageEventListener.bind(this));
    }

    private stopStorageEventListener(): void {
      window.removeEventListener("storage", this.storageEventListener.bind(this));
    }
  
  

    private storageEventListener(event: StorageEvent) {
      if (event.storageArea == localStorage) {
        if (event.key && event.key == 'logout-event') {
          this.logout()
          window.location.href = environment.loginPageUrl
        }
      }
    }
  
}

